import React, { useEffect, useState } from 'react'
import { Alert } from '../../../common/Alert'
import './list-user.scss'
import ListUserTable from './UserListDetails'
import { useTranslation } from 'react-i18next'
import { pushPageData } from '../../../utils/analytics'
import pageNames from '../../../constants/pageNames'
import { PAGE_PRIMARY_CATEGORY, PAGE_SUB_CATEGORY1 } from '../../../constants'
import sessionKeys from '../../../constants/sessionKeys'
import { getFromLocalStorage } from '../../../utils/StorageUtils'
import { removeError, hasError } from '../../CaptureAWB/captureAwbUtils'
import { getUserIdByAgentCode,getUserProfile } from '../../../services/userProfileService'
import MESSAGES from '../../../constants/Messages'
import { Message } from 'primereact/message'
import {
	validateAlphaNumeric,
} from '../userManagementUtil'
import getPrincipal from '../../../utils/getPrincipal'

const ListUser = ({
	userList,
	setUserList,
	showMessage,
	setShowMessage,
	getuser,
	handleUpdateUser,
	userDetails,
	setAddUser,
	updateUserSuccess,
	setUpdateUserSuccess,
	setAddUserSuccess,
	addUserSuccess,
	agentCode,
	setAgentCode,
	isGsaUser,
	setStationCode,
	setDefaultAirportCode,
	hideUserList,
	listRegionBasedUsers,
	setIsLoading,
	isLoading,
	setCustomerCode,
	setCompanyName,
	setCompanyCode,
}) => {
	const [alertMessage, setAlertMessage] = useState('')
	const [updateSuccess, setUpdateSuccess] = useState(false)
	const [ssoTypeUser, setSsoTypeUser] = useState(true)
	const [isEnterpriseUser, setIsEnterpriseUser] = useState(null)
	const [agentUserId, setAgentUserId] = useState(null)
	const [agentSearch, setAgentSearch] = useState(false)

	const ssoOktaType = localStorage.getItem(sessionKeys.SSO_TYPE)
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const handleAlertClose = () => {
		setAlertMessage('')
		setUpdateSuccess(false)
		setUpdateUserSuccess({
			...updateUserSuccess,
			success: false,
			message: '',
		})
		setAddUserSuccess({
			...addUserSuccess,
			success: false,
			message: '',
		})
	}

	useEffect(() => {
		// const ssoTypeCheck = ssoOktaType === "C" ? true : false;
		// setSsoTypeUser(ssoTypeCheck)
		//console.log("asd",userDetails.userType)
		if (updateSuccess) {
			searchUsers()
		}
	}, [updateSuccess])

	const [gsaAgentSearch, setGsaAgentSearch] = useState(false)

	useEffect(() => {
		pushPageData(
			pageNames.LIST_USER,
			PAGE_PRIMARY_CATEGORY.USER_PROFILE,
			PAGE_SUB_CATEGORY1.USER_MANAGEMENT
		)
		const isEnterpriseUser = getFromLocalStorage('SSOType') === 'E' ? true : false
		setIsEnterpriseUser(isEnterpriseUser)

		const userDataSub = getPrincipal()
		setGsaAgentSearch(false)
		if (userDataSub.roles?.includes('GSA_ADMIN_USER')) {
			const gsaAgentDetailsStr = getFromLocalStorage(sessionKeys.GSA_AGENT_DETAILS);
			if (gsaAgentDetailsStr) {
				let gsaAgentDetails = JSON.parse(gsaAgentDetailsStr)
				if (gsaAgentDetails?.agentCode) {
					setAgentCode(gsaAgentDetails?.agentCode)
					setGsaAgentSearch(true)
					setTimeout(() => {
						searchAgentCode(gsaAgentDetails?.agentCode)
					}, 300);
				}
			}
		}
	}, [])

	useEffect(() => {
		if (updateUserSuccess && updateUserSuccess.message) {
			setAlertMessage(updateUserSuccess.message)
			setUpdateSuccess(updateUserSuccess.success)
		}

		else if (addUserSuccess && addUserSuccess.message) {
			setAlertMessage(addUserSuccess.message)
			setUpdateSuccess(addUserSuccess.success)
		}
	}, [updateUserSuccess, addUserSuccess])

	useEffect(() => {
		if(hideUserList){
			setAlertMessage(MESSAGES.RESTRICTED_AGENT_DETAILS_ACCESS)
		}
		else{
			setAlertMessage('')
		}
	},[hideUserList])


	const searchUsers = () => {
		const request = {
			customer:
				userDetails && userDetails.agentCode && !isEnterpriseUser ? userDetails.agentCode : agentCode, 
			companyCode:
				userDetails && userDetails.companyCode ? userDetails.companyCode : 'EY',
			status: null,
		}
		if (request.customer != null || request.customer != undefined) {
			getuser(request)
		}
	}

	useEffect(() => {
		if (userList && userList.length > 0 && agentSearch && !agentUserId) {
			setAgentUserId(userList[0].userId)
			setIsLoading(true)
			getUserProfile({userCode: userList[0].userId}).then((res) => {
				if(res && res.userDetails && (isGsaUser || isEnterpriseUser)){
					setCustomerCode(res.userDetails.customerCode)
					setCompanyName(res.userDetails.companyName)
					setCompanyCode(res.userDetails.companyCode)
					setStationCode(res.userDetails.stationCode)
					setDefaultAirportCode(res.userDetails.defaultAirportCode)
					if(userDetails){
						listRegionBasedUsers(res.userDetails.defaultAirportCode)
					}
					else{
						setIsLoading(false)
					}
				}

			}).catch((error) => {
				console.log(error)
				setIsLoading(false)
			})

		}
	}, [userList])

	const searchAgentCode = (code) => {
		setAgentSearch(true)
		setCustomerCode(null)
		setCompanyName(null)
		setCompanyCode(null)
		setAgentUserId(null)
		setStationCode(null)
		setDefaultAirportCode(null)
		const agentCodeToSearch = code ? code : agentCode
		if (validateAgentCode(agentCodeToSearch)) {
			getUserIdByAgent(agentCodeToSearch);
			const request = {
				customer:
					agentCodeToSearch ? agentCodeToSearch : null,
				companyCode:
					userDetails && userDetails.companyCode ? userDetails.companyCode : 'EY',
				status: null,
			}
			if (request.customer != null || request.customer != undefined) {
				getuser(request)
				setSsoTypeUser(true);
			}
		}
	}

	const getUserIdByAgent = (agentCode) => {
		let reqObj = {
				agentCode: agentCode
			}
		// setIsLoading(true)
		getUserIdByAgentCode(reqObj)
			.then((response) => {
				if (response && response.userId) {
					setAgentUserId(response.userId)
					getUserProfile({userCode: response.userId}).then((res) => {
						if(res && res.userDetails && (isGsaUser || isEnterpriseUser)){
							setCustomerCode(res.userDetails.customerCode)
							setCompanyName(res.userDetails.companyName)
							setCompanyCode(res.userDetails.companyCode)
							setStationCode(res.userDetails.stationCode)
							setDefaultAirportCode(res.userDetails.defaultAirportCode)
							if(userDetails){
								listRegionBasedUsers(res.userDetails.defaultAirportCode)
							}
							else{
								setIsLoading(false)
							}
						}

					})
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleAddUser = () => setAddUser(true)

	// Agent code validation
	const [error, setError] = useState(null)

	const validatAgentCodePattern = (agentCode) => {
		if (agentCode && agentCode.trim() !== "" && (agentCode.length <= 11)
			&& validateAlphaNumeric(agentCode)) {
			return true
		} else {
			return false
		}
	}

	const validateAgentCode = (agentCode) => {
		if (!validatAgentCodePattern(agentCode)) {
			setError((prevState) => ({
				...prevState,
				AgentCode: MESSAGES.VALID_AGENT_CODE,
			}));
			return false;
		} else {
			setError((prevState) => ({
				...prevState,
				AgentCode: "",
			}));
			return true;
		}
	}


	return (
		(userDetails || isGsaUser || isEnterpriseUser) && (
			<div className='list-user'>
				{alertMessage !== '' && (
					<Alert
						isError={!updateSuccess}
						showAlert={true}
						message={t(alertMessage, { ns: 'errors' })}
						onAlertClose={handleAlertClose}
						setTimeout={true}
					/>
				)}
				{
					//isEnterpriseUser && userDetails.userType === 'PA' ? (
						(isEnterpriseUser || isGsaUser) ?(
						<>
							{userList.length > 0 && gsaAgentSearch && !hideUserList ? (<></>) : (
							<div className='form-row'>
								<div className='form'>
									<div className='p-input-icon-right form-item form-item--text' data-module='molecules/form-item/FormItem'>
										<input
											id='AgentCode'
											className='form-item__field'
											value={agentCode}
											maxLength={11}
											placeholder='Agent code'
											//autoComplete='off'
											onChange={(e) => {
												if (e.target.value.length <= 11) {
													//console.log(e.target.value)
													setAgentCode(e.target.value)
												}
												if (e.target.value.length < 11) {
													setUserList([])
													setShowMessage(false)
												}
												//setAgentCode(e.target.value)
											}}
											onBlur={(e) => {
												validateAgentCode(e.target.value, setError)
											}}
											aria-invalid={hasError('AgentCode', error)}
											onFocus={(e) => {
												removeError(e, setError)
											}}

										/>
										<span className='form-item__error'>
											{error && error['AgentCode']
												? t(error['AgentCode'], { ns: 'errors' })
												: ''}
										</span>
									</div>
								</div>
								<button
									type='button'
									className='d-md-inline-block button'
									id='btn-template-search'
									onClick={(e) => searchAgentCode()}
								>
									{t('COMMON.SEARCH')}
								</button>
							</div>
							)}
						</>

					) : <></>
				}

				{
					//(mockUserList && mockUserList.length) ? (
					 (userList && userList.length && !hideUserList && !isLoading) ? (
						
						<div>
							<div id='userTable'>
								<ListUserTable
									//userList={mockUserList}
									userList={userList}
									setUserList={setUserList}
									setAlertMessage={setAlertMessage}
									updateSuccess={updateSuccess}
									setUpdateSuccess={setUpdateSuccess}
									handleUpdateUser={handleUpdateUser}
									
								/>
							</div>
							<div className='pt-4'>
								<button
									id='addUser'
									className='addUserButton'
									onClick={handleAddUser}
								>
									<span>{t('UM.ADD_USER')}</span>
								</button>
							</div>
						</div>
					) : (<>
						{
							(showMessage == true ) ? (
								
								<div>
									<div className='row'>
										<div className='col-12 warning-info'>
											<Message
												severity='info'
												text="No Users present"
												
											></Message>
										</div>
									</div>
									<div className='pt-4'>
										<button
											id='addUser'
											className='addUserButton'
											onClick={handleAddUser}
										>
											<span>{t('UM.ADD_USER')}</span>
										</button>
									</div>
								</div>
							) : (<></>)
						}
					</>)
				}

				{/* {
					(!isEnterpriseUser || agentCode) ?
						(
							<div className='pt-4'>
								<button
									id='addUser'
									className='addUserButton'
									onClick={handleAddUser}
								>
									<span>{t('UM.ADD_USER')}</span>
								</button>
							</div>
						) : (<></>)
				}
 */}
			</div>
		)
	)
}

export default ListUser
