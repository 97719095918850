import { PAGES } from "../../constants";
import MESSAGES from "../../constants/Messages";
import sessionKeys from "../../constants/sessionKeys";
import { getStationByAgentCode } from "../../services/dashboardServices";
import getCurrentUser from "../../utils/getCurrentUser";
import getPrincipal from "../../utils/getPrincipal";
import {
  clearItemFromLocalStorage,
  setToLocalStorage,
} from "../../utils/StorageUtils";
import { validateAlphaNumeric } from '.././UserManagement/userManagementUtil'

export const isStringEmpty = (stringToCheck) =>
  stringToCheck?.trim()?.length === 0;

export const validatAgentCodePattern = (agentCode) => {
  if (agentCode && agentCode.trim() !== "" && (agentCode.length <= 11)
    && validateAlphaNumeric(agentCode)) {
    return true
  } else {
    return false
  }
}

export const doGsaAgentSelect = async (
  agentCode,
  gsaAgentData,
  setIsLoading,
  setError,
  setToGsaAgent,
  history,
  t,
  isHeaderPopupCase
) => {
  let isSuccess = false;
  let principal = getPrincipal();
  let userDetails = getCurrentUser();
  const { agentDetails } = gsaAgentData;
  if (isStringEmpty(agentCode) || !validatAgentCodePattern(agentCode)) {
    if (setError) {
      setError(t(MESSAGES.VALID_AGENT_CODE, { ns: "errors" }));
    }
  } else {
    const currentUserStationList = [];
    if (userDetails && principal?.type !== "enterprise") {
      if (userDetails?.stationCode) {
        currentUserStationList.push(userDetails.stationCode);
      }
      userDetails?.userAllowedStations.forEach((allowdedStation) => {
        if (
          allowdedStation?.additionalRoleGroup?.includes("GSA") ||
          allowdedStation?.stationDefaultRoleGroup === "GSA"
        ) {
          if (allowdedStation?.stationCode) {
            currentUserStationList.push(allowdedStation.stationCode);
          }
        }
      });
    }
    if (currentUserStationList.length || principal?.type === "enterprise") {
      if (setIsLoading) {
        setIsLoading(true);
      }
      try {
        const agentListResponse = await getStationByAgentCode({
          customer: agentCode,
          companyCode: "EY",
        });
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (agentListResponse?.errorDetailApiDto) {
          if (setError) {
            setError(agentListResponse.errorDetailApiDto.errorDescription);
          }
        } else if (
          (agentListResponse?.stationCode &&
            currentUserStationList.includes(agentListResponse.stationCode)) ||
          principal?.type === "enterprise"
        ) {
          let currentUserDetails = userDetails;

          currentUserDetails = {
            ...currentUserDetails,
            agentCode: agentCode,
            customerCode: agentCode,
            currencyCode: agentListResponse?.currencyCode
              ? agentListResponse.currencyCode
              : currentUserDetails?.currencyCode,
            dataCaptureTnCFlag: agentListResponse?.dataCaptureTnCFlag
              ? true
              : false,
            companyName: agentListResponse?.companyName,
          };
          setToLocalStorage(
            sessionKeys.USER_DETAILS,
            JSON.stringify(currentUserDetails)
          );
          if (setToGsaAgent) {
            setToGsaAgent({
              agentCode: agentCode,
              agentStationCode: agentListResponse?.stationCode,
              agentDefaultAirportCode: agentListResponse?.defaultAirportCode,
              currencyCode: agentListResponse?.currencyCode,
              dataCaptureTnCFlag: agentListResponse?.dataCaptureTnCFlag,
              companyName: agentListResponse?.companyName,
              email: agentListResponse?.email,
              contactNo: agentListResponse?.contactNo,
              isValidationSuccess: true,
              userName: agentListResponse?.userName,
              userId: agentListResponse?.userId,
            });
          }
          if (!isHeaderPopupCase && history) {
            const callerScreen = agentDetails?.callerScreen;
            if (callerScreen) {
              switch (callerScreen) {
                case PAGES.CAPTURE:
                  history.push("/capture-awb");
                  break;
                case PAGES.BOOKING:
                  history.push("/booking");
                  break;
                case PAGES.DASHBOARD:
                  history.push("/dashboard");
                  break;
                default:
                  history.push("/booking");
              }
            }
          }
          isSuccess = true;
        } else {
          setError("Sorry, you don't have access to the specified customer data.");
        }
      } catch (error) {
        setError(t(MESSAGES.PROCESS_REQUEST_FAILED, { ns: "errors" }));
        clearItemFromLocalStorage(sessionKeys.GSA_AGENT_DETAILS);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  }

  return isSuccess;
};
