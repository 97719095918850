/* eslint-disable import/prefer-default-export */

import { round } from '../utils/common'
import Messages from '../constants/Messages'

export const ERROR_MESSAGE = {
	REQUEST_TIMEOUT: 'The request timedout.',
	UNEXPECTED_ERROR: Messages.UNEXPECTED_ERROR,
}

export const AUTOCOMPLETE_TYPE = {
	ORIGIN: 'ORIGIN_AUTOCOMPLETE',
	DESTINATION: 'DESTINATION_AUTOCOMPLETE',
}

export const AUTOCOMPLETE_UNID = {
	UNID: 'UNID_AUTOCOMPLETE'
}

export const AUTOCOMPLETE_TYPE_COUNTRY = {
	SHIPPER: 'SHIPPER_COUNTRY_AUTOCOMPLETE',
	CONSIGNEE: 'CONSIGNEE_COUNTRY_AUTOCOMPLETE',
}

export const ACTIVE_VIEW_PANEL = {
	PRICE_GRAPH: 'PRICE_GRAPH',
	PRICE_CALENDAR: 'PRICE_CALENDAR',
	MAP: 'MAP',
}

export const DETAILS_APPLY_FOR = {
	TOTAL: 'TOTAL',
	PER_ITEM: 'PER_ITEM',
}
export const FLIGHT_TYPES = [
	{ value: 'All', label: 'COMMON.ALL' },
	{ value: 'CO', label: 'ROUTING_ENQUIRY.PASSENGER_FLIGHT' },
	{ value: 'C', label: 'ROUTING_ENQUIRY.FREIGHTER_FLIGHT' },
	{ value: 'T', label: 'ROUTING_ENQUIRY.TRUCK' },
]

export const ADMIN_MASTER_TYPES = [
	{ value: 'select', label: 'Select' },
	{ value: 'product', label: 'Product' },
	{ value: 'service', label: 'Service' },
	{ value: 'commodity', label: 'Commodity' },
	{ value: 'airport', label: 'Airport' },
	{ value: 'scc', label: 'Scc' },
	{ value: 'uldType', label: 'ULD type' },
	{ value: 'pivotInfo', label: 'Pivot info' },
	{ value: 'sccIncompatible', label: 'Scc incompatibility' },
	{ value: 'stationSchedule', label: 'Station schedule' },
	{ value: 'embargoMaster', label: 'Embargo Restricted' },
	{ value: 'region', label: 'Region' },
	{ value: 'country', label: 'Country' },
]

export const TERMS_AND_CONDITIONS_LEVEL_TYPES = [
	{ value: 'Select', label: 'Select' },
	{ value: 'Global', label: 'Global' },
	{ value: 'Country', label: 'Country' },
]

export const AIRCRAFT_CLASSIFICATIONS = [
	{ value: 'All', label: 'COMMON.ALL' },
	{ value: 'W', label: 'COMMON.WB' },
	{ value: 'N', label: 'COMMON.NB' },
]

export const UNID_FORM_FIELDS = {
	UNID: 'UNID'
}

export const LOOSE_FORM_FIELDS = {
	PIECES: 'PIECES',
	LENGTH: 'LENGTH',
	WIDTH: 'WIDTH',
	HEIGHT: 'HEIGHT',
	WEIGHT: 'WEIGHT',
}

export const ULD_FORM_FIELDS = {
	ULD_TYPE: 'ULD_TYPE',
	CONTOUR: 'CONTOUR',
	PIECES: 'PIECES',
	WEIGHT: 'WEIGHT',
}

export const FLIGHT_ENQUIRY_FORM_FIELDS = {
	ORIGIN: 'ORIGIN',
	DESTINATION: 'DESTINATION',
	FLIGHTDATE: 'FLIGHTDATE',
	FLIGHTNUMBER: 'FLIGHTNUMBER',
}

export const PHARMA_VACCINE_FIELDS = {
	SHIPPING_TYPE: 'SHIPPING_TYPE',
	HYBRID_CONTAINER_TYPE: 'HYBRID_CONTAINER_TYPE',
	ACTIVE_CONTAINER_TYPE: 'ACTIVE_CONTAINER_TYPE',
	TEMP_RANGE: 'TEMP_RANGE',
	DRY_ICE: 'DRY_ICE',
}

const INIT_LOOSE_ITEM_Detail = {
	pieces: '',
	length: '',
	width: '',
	height: '',
	weightType: DETAILS_APPLY_FOR.PER_ITEM,
	weight: '',
	stackable: true,
	turnable: false,
}
const INIT_PHARMA_OBJECT = {
	shippingPharmaType: null,
	activeContainerType: null,
	hybridContainerType: null,
	tempratureRange: null,
	isDryIceAdded: null,
}

const INIT_ULD_ITEM_Detail = {
	weight: '',
	uldType: null,
	uldVolume: null,
	pieces: null,
	contour: null,
	pivotWeight: null,
}
const INIT_SEARCH_Summary = {
	quantity: 0,
	totalVolume: 0.0,
	totalDensity: 0.0,
}

const INIT_PET_ITEM_Detail = {
	breed: '',
	otherBreed: null,
	name: '',
	ageInYear: null,
	ageInMonths: null,
	medicalCondition: '',
	petOrder: 0,
	cageOrder: 0,
	petCode: '',
	petKey: '',
	id: 0,
	stubNose: null,
	pieces: 1,
	attachments: [],
	error: null
}

const INIT_UNID__Detail = {
	id: '',
	unid: '',
	unidSelectedValidation: [],
	unidName: '',
	specialProvison: false,
	dangerousGoods: false,
	packingGroup: ''
}

const INIT_DG_ATTACHMENT_Detail = [
	{
		attachmentId: '',
		fileName: ''
	}
]

export const getSummaryDetail = () => ({ ...INIT_SEARCH_Summary })
export const getInitLooseItemDetail = () => ({ ...INIT_LOOSE_ITEM_Detail })
export const getInitdgAttachmentDetail = () => ({ ...INIT_DG_ATTACHMENT_Detail })
export const getInitPharmaObject = () => ({ ...INIT_PHARMA_OBJECT })
export const getInitULDItemDetail = () => ({ ...INIT_ULD_ITEM_Detail })
export const getInitPetDetail = () => ({ ...INIT_PET_ITEM_Detail })
export const getInitUnidDetail = () => ({ ...INIT_UNID__Detail })
export const getInitCageDetail = () => ({ ...INIT_CAGE_ITEM_Detail })
export const getInitSeasonalFlightDetails = () => ({ ...INIT_SEASONAL_FLIGHT_DETAILS })
export const getVolumeUnit = (value) => {
	if (value === 'B') return 'CBM'
	if (value === 'C') return 'Cc'
	if (value === 'I') return 'CIn'
	if (value === 'F') return 'CFT'
}
const INIT_SEASONAL_FLIGHT_DETAILS = {
	flightNumber: '',
	flightDate: '',
	destination: '',
	allotmentId: ''
}

const INIT_CAGE_ITEM_Detail = {
	weight: null,
	length: null,
	width: null,
	height: null,
	measurement: 'C',
	weightUnit: 'K',
	pets: [getInitPetDetail()],
	id: 0,
	pieces: 1,
	cageOrder: 0,
}

export const getInitCageDetails = (weightUnit, dimensionUnit) => {
	let cageDetails = INIT_CAGE_ITEM_Detail
	if (weightUnit) {
		cageDetails.weightUnit = weightUnit
	}
	if (dimensionUnit) {
		cageDetails.measurement = dimensionUnit
	}
	return cageDetails
}

export const calculateDensity = (totalVolume, totalWeight) => {
	if (totalVolume) {
		totalVolume = totalVolume.toFixed(2)
	}
	let density = ((totalWeight * 1) / (totalVolume * 1)).toFixed(2)

	if (density && density < 0.01) {
		return 0.01
	} else {
		return density
	}
}
export const getDecimalFormattedNumber = (value) => {
	return Number(parseFloat(value).toFixed(2)).toLocaleString('en', {
		minimumFractionDigits: 2,
	})
}
export const getOneDecimalFormattedNumber = (value) => {
	return Number(parseFloat(value).toFixed(1)).toLocaleString('en', {
		minimumFractionDigits: 1,
	})
}
export const getFormattedNumber = (value) => {
	return Number(parseFloat(value).toFixed(2)).toLocaleString('en')
}
export const capitalize = (str) => {
	return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
}
export const FORM_FIELDS = {
	ORIGIN: 'ORIGIN',
	DESTINATION: 'DESTINATION',
	SHIPPING_DATE: 'SHIPPING_DATE',
	ARRIVAL_DATE: 'ARRIVAL_DATE',
	SHIPPING_ITEM: 'SHIPPING_ITEM',
	TOTAL_WEIGHT: 'TOTAL_WEIGHT',
	LOOSE_ITEMS: 'LOOSE_ITEMS',
	ULD_ITEMS: 'ULD_ITEMS',
	CAGE_ITEMS: 'CAGE_ITEMS',
	CALCULATED_WEIGHT: 'CALCULATED_WEIGHT',
	UNKNOWN_SHIPMENT: 'UNKNOWN_SHIPMENT',
	OPERATIONAL_WEIGHT: 'OPERATIONAL_WEIGHT',
	CRATE_TOTAL_WEIGHT: 'CRATE_TOTAL_WEIGHT',
	CRATE_LENGTH: 'CRATE_LENGTH',
	CRATE_WIDTH: 'CRATE_WIDTH',
	CRATE_HEIGHT: 'CRATE_HEIGHT',
	UNID: 'UNID',
	PETS: 'PETS',
	BREED: 'BREED',
	AGE: 'AGE',
	AGE_YEARS: 'AGE_YEARS',
	AGE_MONTHS: 'AGE_MONTHS',
	INVALID_REPORT_END_DATE: 'INVALID_REPORT_END_DATE',
	INVALID_REPORT_START_DATE: 'INVALID_REPORT_START_DATE',
	INVALID_REPORT_MONTH: 'INVALID_REPORT_MONTH',
	PROVISON_ERROR :'PROVISON'
}

export const SEASONAL_FIELDS = {
	FLIGHT_LIST: 'FLIGHT_LIST',
	FLIGHT_NUMBER: 'FLIGHT_NUMBER',
	FLIGHT_DATE: 'FLIGHT_DATE',
	DESTINATION: 'DESTINATION',
	ALLOTMENT_ID: 'ALLOTMENT_ID',
	ON_DAY: 'ON_DAY'
}

export const getRoundedValue = (value) => {
	let toFixedValue = Number(value)
	toFixedValue = round(toFixedValue, 2)
	let difference = toFixedValue - parseInt(toFixedValue)
	if (difference < 0.5 && difference !== 0) {
		return parseInt(toFixedValue) + 0.5
	} else if (difference > 0.5) {
		return parseInt(toFixedValue) + 1
	} else {
		return toFixedValue
	}
}

export const getRoundedValueDecimal = (value) => {
	let toFixedValue = Number(value)
	toFixedValue = round(toFixedValue, 2)
	let difference = toFixedValue - parseInt(toFixedValue)
	if (difference < 0.5 && difference !== 0) {
		return round(toFixedValue, 0)
	} else {
		return toFixedValue
	}
}

export const getWeightUnit = (value) => {
	if (value === 'K') return 'kg'
	if (value === 'L') return 'lb'
	if (value === 'T') return 't'
}

export const getDensityUnit = (weightUnit, dimensionUnit) => {
	let Wunit = getWeightUnit(weightUnit)
	let Dunit = getVolumeUnit(dimensionUnit)
	return Wunit + '/' + Dunit
}

export const SHIPPING_TYPE = {
	ULD: 'ULD',
	LOOSE: 'LOOSE',
}

export const IS_KNOWN_UNKNOWN = {
	// UNKNOWN: 'Y',
	// KNOWN: 'N',
	UNKNOWN: 'UNKNOWN',
	KNOWN: 'KNOWN',
}

export const PRICE_CODE_TYPE = {
	PROMO_RATE: 'PROMO_RATE',
	SPOT_RATE: 'SPOT_RATE',
}

export const SEARCH_DATE_TOGGLE_TYPE = {
	SHIPPING_DATE: 'SHIPPING_DATE',
	ARRIVAL_DATE: 'ARRIVAL_DATE',
}

export const PHARMA_SHIPPING_TYPE = {
	HYBRID: 'HYBRID',
	ACTIVE: 'ACTIVE',
	PASSIVE: 'PASSIVE',
}
export const PHARMA_ACTIVE_TYPE = {
	OWN_ACTIVE: 'OWN ACTIVE CONTAINER',
	EY_ACTIVE: 'EY ACTIVE CONTAINER',
}
export const PHARMA_HYBRID_TYPE = {
	OWN_HYBRID: 'OWN HYBRID CONTAINER',
	EY_HYBRID: 'EY HYBRID CONTAINER',
}

export const PHARMA_TEMP_RANGE = {
	FIRST: '2-8 C',
	SECOND: '15-25 C',
	THIRD: '2-25 C',
	FROZEN: 'FROZEN',
	NA: 'NO TEMPERATURE CONTROL',
}

export const SCC_CODES = {
	PIP: 'PIP',
	ACT: 'ACT',
	ICE: 'ICE',
	FRO: 'FRO',
	TMP1: 'COL',
	TMP2: 'CRT',
	TMP3: 'ERT',
}

export const PHARMA_PRODUCT = 'PharmaLife'

export const FRESH_FORWARD_PRODUCT = 'FreshForward'

export const WEIGHT_UNIT = {
	KILOGRAM: 'K',
	POUND: 'L',
	TON: 'T',
}

export const WEIGHT_UNIT_LABEL = {
	K: 'kg',
	L: 'lb',
	T: 'tons',
}

export const VOLUME_UNIT_LABEL = {
	B: 'CBM',
	F: 'CFT',
	I: 'CIn',
	C: 'Cc',
}

export const VOLUME_UNIT = {
	CBM: 'B',
	CFT: 'F',
	CIn: 'I',
	Cc: 'C',
}

export const DIMENSION_UNIT = {
	METERS: 'B',
	CENTIMETERS: 'C',
	INCHES: 'I',
	FOOT: 'F',
}

export const WEIGHT_UNIT_SELECT_ITEMS = [
	{ label: 'kg', value: WEIGHT_UNIT.KILOGRAM },
	{ label: 'lb', value: WEIGHT_UNIT.POUND },
	{ label: 't', value: WEIGHT_UNIT.TON },
]

export const WEIGHT_UNIT_SELECT_ITEMS_WO_TON = [
	{ label: 'kg', value: WEIGHT_UNIT.KILOGRAM },
	{ label: 'lb', value: WEIGHT_UNIT.POUND },
]

export const VOLUME_UNITS_SELECT_ITEMS = [
	{ label: 'Select', value: '' },
	{ label: 'CBM', value: VOLUME_UNIT.CBM },
	{ label: 'CIn', value: VOLUME_UNIT.CIn },
	{ label: 'Cc', value: VOLUME_UNIT.Cc },
]

export const VOLUME_CODE_UNIT_MAPPING = {
	MC: 'B',
	CC: 'C',
	CF: 'F',
	CI: 'I',
}

export const DIMENSION_UNIT_SELECT_ITEMS = [
	// { label: 'Meter', value: DIMENSION_UNIT.METERS },
	{ label: 'cm', value: DIMENSION_UNIT.CENTIMETERS },
	{ label: 'inch', value: DIMENSION_UNIT.INCHES },
	// { label: 'Foot', value: DIMENSION_UNIT.FOOT },
]

export const SCI_SELECT_ITEMS = [
	{ label: 'COMMON.SELECT', value: '' },
	{ label: 'F', value: 'F' },
	{ label: 'TF', value: 'TF' },
	{ label: 'TD', value: 'TD' },
	{ label: 'X', value: 'X' },
	{ label: 'T1', value: 'T1' },
	{ label: 'T2', value: 'T2' },
	{ label: 'C', value: 'C' },
]

export const PAYMENT_TYPES_SELECT_ITEMS = [
	{ label: 'COMMON.SELECT', value: '' },
	{ label: 'PP', value: 'P' },
	{ label: 'CC', value: 'C' },
]

export const PAYMENT_TYPES_SELECT_ITEMS_HAWB = [
	{ label: 'PP', value: 'P' },
	{ label: 'CC', value: 'C' },
]

export const PAYMENT_TYPES = {
	PP: 'P',
	CC: 'C',
}

export const CHARGE_ENTITLEMENT_PARTY_CODES = [
	{ label: 'COMMON.SELECT', value: '' },
	{ label: 'CAPTURE_AWB.CARRIER', value: 'C' },
	{ label: 'CAPTURE_AWB.AGENT', value: 'A' },
]

export const ACCOUNTING_IDENTIFIER_SELECT_ITEMS = [
	{ label: 'COMMON.SELECT', value: '' },
	{ label: 'CAPTURE_AWB.CREDIT_EXP_DATE', value: 'CRD' },
	{ label: 'CAPTURE_AWB.CREDIT_INS_NAME', value: 'CRI' },
	{ label: 'CAPTURE_AWB.CREDIT_CARD_NUMBER', value: 'CRN' },
	{ label: 'CAPTURE_AWB.GEN_INFO', value: 'GEN' },
	{ label: 'CAPTURE_AWB.BILL_LADING', value: 'GBL' },
	{ label: 'CAPTURE_AWB.MIS_CHARGE_ORDER', value: 'MCO' },
	{ label: 'CAPTURE_AWB.MODE_SETTLEMENT', value: 'STL' },
	{ label: 'CAPTURE_AWB.RETURN_ORIGIN', value: 'RET' },
	{ label: 'CAPTURE_AWB.SHIPPERS_REF_NUMBER', value: 'SRN' },
]

export const RATE_CLASS_CODE_SELECT_ITEMS = [
	{ label: 'COMMON.SELECT', value: '' },
	{ label: 'B', value: 'B' },
	{ label: 'R', value: 'R' },
	{ label: 'S', value: 'S' },
	{ label: 'P', value: 'P' },
	{ label: 'M', value: 'M' },
	{ label: 'N', value: 'N' },
	{ label: 'Q', value: 'Q' },
	{ label: 'K', value: 'K' },
	{ label: 'C', value: 'C' },
	{ label: 'X', value: 'X' },
	{ label: 'E', value: 'E' },
	{ label: 'U', value: 'U' },
	{ label: 'Y', value: 'Y' },
	{ label: 'W', value: 'W' },
]

export const VOLUME_UNIT_VALUES = {
	CUBIC_METERS: 'MC',
	CUBIC_CENTIMETERS: 'CC',
	CUBIC_INCHES: 'CI',
}

export const VOLUME_UNIT_SELECT_ITEMS = [
	{ label: 'CBM', value: VOLUME_UNIT_VALUES.CUBIC_METERS },
	{ label: 'Cc', value: VOLUME_UNIT_VALUES.CUBIC_CENTIMETERS },
	{ label: 'CIn', value: VOLUME_UNIT_VALUES.CUBIC_INCHES },
]

export const SERVICES = {
	TEMP_CHECK: 'Temp Check',
	FAST_TRACK: 'XPS',
}

export const DISPLAY_SERVICES = {
	XPS: 'FastTrack',
}

export const BOOKING_STATUSES = {
	X: 'COMMON.CNC',
	C: 'COMMON.CONFIRMED',
	Q: 'COMMON.QUEUED',
	R: 'VIEWBKG.ST_REJECTED',
	W: 'DASHBOARD.BOOKING_WAITLISTED',
}

export const TIMEOUT_TIME = 5 * 60 * 1000

export const CONTOUR_ITEMS = [
	{ label: 'Main Deck', value: 'MD' },
	{ label: 'Lower Deck', value: 'LD' },
]

export const BOOKING_STATUS = [
	{ label: 'COMMON.ALL', value: null },
	{ label: 'COMMON.CONFIRMED', value: 'C' },
	{ label: 'COMMON.QUEUED', value: 'Q' },
	{ label: 'COMMON.CNC', value: 'X' },
]

export const CARRIER_CODE = 'EY'

export const KG_TO_POUND_VALUE = 2.20462
export const KG_TO_TON_VALUE = 0.00110231

export const USER_TYPES = [
	{ label: 'COMMON.USER', value: 'EU' },
	{ label: 'COMMON.ADMIN', value: 'KU' },
]

export const USER_ACCOUNT_STATUS = [
	{ label: 'COMMON.ACTIVE', value: 'A' },
	{ label: 'COMMON.INACTIVE', value: 'I' },
]
/*
export const USER_TYPE = [
	{ label: 'All', value: '' },
	{ label: 'Admin', value: 'KU' },
	{ label: 'User', value: 'EU' },
]

export const USER_STATUS = [
	{ label: 'Active', value: 'true' },
	{ label: 'In-Active', value: 'false' },
	{ label: 'All', value: '' },
]
*/
export const ACCOUNT_DISABLED_FLAG = {
	ACTIVE: 'Y',
	INACTIVE: 'N',
}

export const SUCCESS_STATUS = 'S'
export const SAVE_OPERATIONAL_FLAG = 'I'
export const UPDATE_OPERATIONAL_FLAG = 'U'

export const MILESTONES = [
	// {
	// 	label: 'All',
	// 	id: 'milestone1',
	// },
	{
		label: 'Accepted',
		id: 'milestone2',
	},
	{
		label: 'Departed',
		id: 'milestone3',
	},
	{
		label: 'Arrived',
		id: 'milestone4',
	},
	{
		label: 'Notified',
		id: 'milestone5',
	},
	{
		label: 'Delivered',
		id: 'milestone6',
	},
]

export const SYSTEM_ADDED_SCCS = ['EYP', 'ERR']

export const PRINT_TYPES = {
	AWB_L_PRINT: 'AWB_L_PRINT',
	CAP_AWBPRINT: 'CAP_AWBPRINT',
}

export const LANGUAGES = [
	{ label: 'COMMON.EN', value: 'en_US' },
	// { label: 'Arabic', value: 'ar_AE' },
	{ label: 'COMMON.ZH', value: 'zh_CN' },
]

export const phoneNumberPattern = new RegExp(/^[0-9 ]+$/)
export const alphaNumbericPattern = new RegExp(/^[a-zA-Z0-9 ]+$/)
export const suppleCusInfoPattern = new RegExp(/^[a-zA-Z0-9\-\\.\ ]+$/)
export const alphaPattern = new RegExp(/^[a-zA-Z ]+$/)
export const emailPattern = new RegExp(
	/^([a-zA-Z0-9_\.\-+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/
)
export const numericPattern = new RegExp(/^\d*$/)

export const decimalPattern = new RegExp(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/)

export const VALIDATE_BOOKINGS_ERROR_CODES = [
	'stockcontrol.defaults.awbnumbernotfoundinanystock',
]

export const zipCodePattern = new RegExp(/^[a-zA-Z0-9\-\ ]+$/)
export const addressPattern = new RegExp(/^[a-zA-Z0-9\-\\.\ ]+$/)


// configurable items
export const EMAIL_RECIPIENTS_LIMIT = 5

export const SHIPPIMG_TYPE_SELECT_ITEMS = [
	{ label: 'Loose', value: SHIPPING_TYPE.LOOSE },
	{ label: 'ULD', value: SHIPPING_TYPE.ULD },
]

export const NOTIFICATION_MESSAGE_TYPE = {
	INFO: 'INFO',
	ERROR: 'ERROR',
	WARNING: 'WARNING',
}

export const USER_PRIVLILEGES = {
	DASHBOARD_PRIVILEGE: 'DASHBOARD_PRIVILEGE',
	BOOKING_PRIVILEGE: 'BOOKING_PRIVILEGE',
	CAPTURE_PRIVILEGE: 'CAPTURE_PRIVILEGE',
	REVENUE_INFOGRAPHICS_PRIVILEGE: 'REVENUE_INFOGRAPHICS_PRIVILEGE',
	ALLOTMENT_PRIVILEGE: 'ALLOTMENT_PRIVILEGE',
	EY_ADMIN_PRIVILEGE: 'EY_PORTAL_ADMIN',
	USER_MANAGEMENT: 'USER_MANAGEMENT',
	CSN_DASHBOARD_PRIVILEGE: 'CSN_USER',
	GSA_USER_PRIVILEGE: 'GSA_USER',
	NETWORK_USER_PRIVILEGE: 'NETWORK_USER',
	GSA_ADMIN_USER_PRIVILEGE: 'GSA_ADMIN_USER',

}

export const PAGES = {
	BOOKING: 'BOOKING',
	CAPTURE: 'CAPTURE',
	DASHBOARD: 'DASHBOARD',
	CSNDASHBOARD: 'CSNDASHBOARD',
	ADMIN: 'ADMIN',
	USERMANAGEMENT: 'USERMANAGEMENT',
	REPORTS: 'REPORTS',
	SCHEDULEVISUALIZATION: 'SCHEDULEVISUALIZATION',
	GSAUSERAGENTSELECT: 'GSAUSERAGENTSELECT'
}

export const MENU_ITEMS_BOOKING = {
	TRACK: 'TRACK',
	COPY: 'COPY',
}
export const ULD_TYPES_WITH_CONTOUR = ['PMC', 'PAJ']

export const PAGE_PRIMARY_CATEGORY = {
	USER_PROFILE: 'user-profile',
	E_SERVICES: 'E-services',
}

export const PAGE_SUB_CATEGORY1 = {
	USER_MANAGEMENT: 'user_management',
	BOOKING: 'booking',
	DASHBOARD: 'dashboard',
	FLIGHT_ENQUIRY: 'routing_enquiry',
	TEMPLATES: 'templates ',
	CAPTURE: 'capture',
	ADMIN: 'admin',
}

export const BOOKING_PAGE_SUB_CATEGORY2 = {
	NEW_BOOKING: 'New booking',
	MY_BOOKINGS: 'My bookings',
	MANAGE_TEMPLATES: 'Templates',
	ROUTING_ENQUIRY: 'Routing enquiry',
	MULTIPLE_BOOKING: 'Multiple booking',
}

export const CAPTURE_PAGE_SUB_CATEGORY2 = {
	CAPTURE_AWB: 'Airway bill',
	CAPTURE_HAWB: 'House airway bill',
}

export const GSA_USER_CONST = {
	CONSUMER: 'consumer',
	PROCUREMENT_GROUP: 'etihadcargo-website-procurementworkflow'
}

export const LOOSE_ITEM_KEYS = {
	LENGTH: 'length',
	WIDTH: 'width',
	HEIGHT: 'height',
	PIECES: 'pieces',
	WEIGHT: 'weight',
	TILTABLE: 'tiltable',
	STACKABLE: 'stackabe',
	WEIGHT_UNIT: 'weightUnit',
	DIMENSION_UNIT: 'dimensionUnit'
}

export const AWB_REPORT_TYPE = [
	{ label: 'Excel', value: 'csv' },
	{ label: 'PDF', value: 'pdf' },
]

export const REPEAT_TYPE = [
	{ label: 'Daily', value: 'daily' },
	{ label: 'Weekly', value: 'weekly' },
	// { label: 'Monthly', value: 'monthly' },
]


