import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, useHistory } from 'react-router-dom'
import { PAGES, USER_PRIVLILEGES, GSA_USER_CONST } from '../../../constants'
import MESSAGES from '../../../constants/Messages'
import sessionKeys from '../../../constants/sessionKeys'
import { validateSession } from '../../../services/authService'
import { getUserDetails, getPLACIUserDetails } from '../../../services/onLoadServices'
import { getLanguageCode } from '../../../utils/common'
import getCurrentUser from '../../../utils/getCurrentUser'
import getPLACIUser from '../../../utils/getPLACIUser'
import getPrincipal from '../../../utils/getPrincipal'
import {
	clearItemFromLocalStorage,
	getFromLocalStorage,
	setToLocalStorage,
} from '../../../utils/StorageUtils'
import ErrorPage from '../../ErrorPage'
import { MULTILANGUAGE_SUPPORT_ENABLED } from '../../../configs/featureControlConfig'
import { getUserConfig } from '../../../services/permanentService';

const SecureRoute = ({
	path,
	component,
	principal,
	refreshToken,
	accessToken,
	setRefreshToken,
	setAccessToken,
	clearAuth,
	triggerLoading,
	showErrorPopup,
	page,
	gsaAgentData,
	setToGsaAgent
}) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [userDetails, setUserDetails] = useState(null)
	const [isAcessDenied, setIsAcessDenied] = useState(false)
	const [isGsaUser, setIsGsaUser] = useState(false)
	const [isCSNUser, setIsCSNUser] = useState(false)
	const [showGsaUserPopup, setShowGsaUserPopup] = useState(false);
	const history = useHistory()



	//for multilingual
	const { t, i18n } = useTranslation(['labels', 'errors'])

	const RedirectToLogin = () => {
		history.push('/logout')
	}

	useEffect(() => {
		triggerLoading(isLoading)
		document.title = t('COMMON.EY_CARGO_PORTAL')
	}, [isLoading])

	useEffect(() => {
		; (async () => {
			if (!accessToken) {
				if (getFromLocalStorage(sessionKeys.ACCESS_TOKEN)) {
					setAccessToken(getFromLocalStorage(sessionKeys.ACCESS_TOKEN))
				} else {
					setIsLoading(false)
					setIsAuthenticated(false)
				}
			}

			if (accessToken || getFromLocalStorage(sessionKeys.ACCESS_TOKEN)) {
				try {
					const validateSessionResponse = await validateSession()
					if (validateSessionResponse.status !== 200) {
						throw 'Token validation unsuccessful'
					}
					setIsAuthenticated(true)
					let principal = getPrincipal()
					let currUserDetails = getCurrentUser()
					if (!principal) {
						throw 'No PRINCIPAL object found in session'
					}
					if (!currUserDetails) {
						const userId = principal.sub
						try {
							const userDetailsResponse = await getUserDetails(userId)
							currUserDetails = userDetailsResponse.userDetails
							MULTILANGUAGE_SUPPORT_ENABLED &&
								currUserDetails?.preference?.language
								? i18n.changeLanguage(
									getLanguageCode(currUserDetails.preference.language)
								)
								: i18n.changeLanguage(getLanguageCode('en_US'))
							document.title = t('COMMON.EY_CARGO_PORTAL')
							currUserDetails.defaultAgentCode = currUserDetails.agentCode
							currUserDetails.defaultCurrencyCode = currUserDetails.currencyCode
							setUserDetails(currUserDetails)
							setToLocalStorage(
								sessionKeys.USER_DETAILS,
								JSON.stringify(currUserDetails)
							)
							const permanentBkgConfig = await getUserConfig()
							let permanentBkgFlag = 'N'
							if (permanentBkgConfig && permanentBkgConfig.permanentBookingFlag) {
								permanentBkgFlag = permanentBkgConfig.permanentBookingFlag
							}
							setToLocalStorage(sessionKeys.PERMANENT_BKG_FLG, permanentBkgFlag)
						} catch (err) {
							setIsLoading(false)
							console.log(err)
							clearItemFromLocalStorage(sessionKeys.USER_DETAILS)
							clearItemFromLocalStorage(sessionKeys.SSO_REFRESH_URL)
						}
					} else {
						setUserDetails(currUserDetails)
					}
					try {
						if (principal && principal.roles?.includes('CSN_USER') && principal.type === 'consumer') {
							const placiUserDetails = getPLACIUser()
							if (!placiUserDetails) {
								setIsLoading(true)
								const userDetailsResponse = await getPLACIUserDetails(principal.sub)
								setIsLoading(false)
								if (userDetailsResponse && userDetailsResponse.data) {
									setToLocalStorage(
										sessionKeys.PLACI_USER_DETAILS,
										JSON.stringify(userDetailsResponse.data)
									)
								}
							}
						}
					} catch (err) {
						setIsLoading(false)
						console.log(err)
						clearItemFromLocalStorage(sessionKeys.PLACI_USER_DETAILS)
					}
					setTimeout(() => {
						setIsLoading(false)
					}, 1000)
				} catch (err) {
					clearItemFromLocalStorage(sessionKeys.ACCESS_TOKEN)
					clearItemFromLocalStorage(sessionKeys.USER_DETAILS)
					clearItemFromLocalStorage(sessionKeys.PLACI_USER_DETAILS)
					clearItemFromLocalStorage(sessionKeys.SSO_REFRESH_URL)
					setIsLoading(false)
					setIsAuthenticated(false)
				}
			}
		})()
	}, [])

	useEffect(() => {
		// if(principal && principal.type === GSA_USER_CONST.CONSUMER && principal.groups === GSA_USER_CONST.PROCUREMENT_GROUP){
		// 	setIsGsaUser(true)
		// }
		if (principal && principal.roles?.includes('GSA_USER')) {
			setIsGsaUser(true)
		}
		if (principal && principal.roles?.includes('CSN_USER')) {
			setIsCSNUser(true)
		}
	}, [principal])

	const doSelectAgentValidation = (callerScreen) => {
		if (principal.roles.includes('GSA_ADMIN_USER')) {
			const { agentDetails } = gsaAgentData
			if (agentDetails?.agentCode && agentDetails?.isValidationSuccess) {
				setIsAcessDenied(false)
			} else if (principal?.type === 'enterprise' && userDetails?.defaultAgentCode && userDetails?.defaultAgentCode !== '') {
				setToGsaAgent({
					agentCode: userDetails.defaultAgentCode,
					agentStationCode: userDetails?.stationCode,
					agentDefaultAirportCode: userDetails?.defaultAirportCode,
					companyName: userDetails?.companyName,
					email: userDetails?.email,
					contactNo: userDetails
						? userDetails.mobileNo
							? userDetails.mobileNo
							: userDetails.telephoneNo
						: null,
					currencyCode: userDetails?.currencyCode,
					dataCaptureTnCFlag: userDetails?.dataCaptureTnCFlag,
					isValidationSuccess: true,
					userName: userDetails
						? `${userDetails.firstName ? userDetails.firstName : ''} ${userDetails.lastName ? userDetails.lastName : ''}`
						: null,
					userId: userDetails?.userId,
				});
			} else {
				if (callerScreen) {
					setToGsaAgent({ ...agentDetails, callerScreen: callerScreen })
					history.push('/user-select')
				}
			}
		}
	}

	useEffect(() => {
		if (!isLoading && isAuthenticated && page !== null && page !== undefined) {
			if (principal && principal.roles && principal.roles.length || isGsaUser) {
				if (
					PAGES.ADMIN === page &&
					!principal.roles.includes(USER_PRIVLILEGES.EY_ADMIN_PRIVILEGE)
				) {
					setIsAcessDenied(true)
				} else if (
					PAGES.BOOKING === page &&
					!principal.roles.includes(USER_PRIVLILEGES.BOOKING_PRIVILEGE)
				) {
					setIsAcessDenied(true)
					doSelectAgentValidation(PAGES.BOOKING);
				} else if (
					PAGES.CAPTURE === page &&
					!principal.roles.includes(USER_PRIVLILEGES.CAPTURE_PRIVILEGE)
				) {
					setIsAcessDenied(true)
					doSelectAgentValidation(PAGES.CAPTURE);
				} else if (
					PAGES.DASHBOARD === page &&
					!principal.roles.includes(USER_PRIVLILEGES.DASHBOARD_PRIVILEGE)
				) {
					setIsAcessDenied(true)
					doSelectAgentValidation(PAGES.DASHBOARD);
				} else if (
					PAGES.CSNDASHBOARD === page &&
					!principal.roles.includes(USER_PRIVLILEGES.CSN_DASHBOARD_PRIVILEGE)
				) {
					setIsAcessDenied(true)
				} else if (
					PAGES.SCHEDULEVISUALIZATION === page &&
					!principal.roles.includes(USER_PRIVLILEGES.NETWORK_USER_PRIVILEGE)
				) {
					setIsAcessDenied(true)
				}
				else {
					setIsAcessDenied(false)
					if (principal.type && principal.type === 'enterprise') {
						doSelectAgentValidation();
					}
				}
			}
			// if (principal && principal.roles && principal.roles.length) {
			// 	if (
			// 		PAGES.ADMIN === page &&
			// 		!principal.roles.includes(USER_PRIVLILEGES.EY_ADMIN_PRIVILEGE)
			// 	) {
			// 		setIsAcessDenied(true)
			// 	}
			// }
		}
	}, [isLoading, isAuthenticated, page, userDetails])

	if (isLoading) {
		return <h1>{t(MESSAGES.LOADING, { ns: 'errors' })}</h1>
	}

	if (!isLoading && !isAuthenticated) {
		RedirectToLogin()
		return null
	}

	if (!isLoading && !userDetails && !isGsaUser && !isCSNUser) {
		showErrorPopup(
			'Error',
			'No User Mapped/Error getting user information',
			'Logout',
			RedirectToLogin
		)
		return null
	}

	const validationFlagHandler = (flag) => flag ? setIsAcessDenied(false) : null

	const handleAgentPopupClose = () => setShowGsaUserPopup(false)

	return (
		<>
			<Route path={path} component={!isAcessDenied ? component : ErrorPage} />
		</>
	);
}

export default SecureRoute
