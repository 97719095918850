import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
	getBookingListResponseDashboard,
	getDashboardChartData,
} from '../../services/dashboardServices'
import getCurrentUser from '../../utils/getCurrentUser'
import LoadingIndicator from '../LoadingIndicator'
import AllotmentDetails from './AllotmentDetails'
import ChartFilter from './ChartFilter'
import ProductChart from './DoughnutChart/ProductChart'
import ServiceTypeChart from './DoughnutChart/ServiceTypeChart'
import NewBookingTile from './NewBookingTile'
import RecentBookingDetails from './RecentBookingDetails'
import RevenueIndicator from './RevenueIndicator'
import ShipmentTracking from './ShipmentTracking'
import SummaryChart from './SummaryChart'
import NewsFeed from './NewsFeed'
import getPrincipal from '../../utils/getPrincipal'
import { USER_PRIVLILEGES } from '../../constants'
import pageNames from '../../constants/pageNames'
import { addError, pushPageData,pushEventBookingDashboard,pushEventBookingTime,pushEventDashboardBookingandTracking} from '../../utils/analytics'
import {PAGE_PRIMARY_CATEGORY,PAGE_SUB_CATEGORY1,}from '../../constants'
const DashboardContainer = () => {
	const [chartData, setChartData] = useState(null)
	const [chartDataType, setChartDataType] = useState('awbCount')
	const [period, setPeriod] = useState('YEARLY')
	const [recentBookings, setRecentBookings] = useState(null)
	const [defaultOrigin, setDefaultOrigin] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [lastUpdatedDate, setLastUpdatedDate] = useState('')
	const initialPageNumber = 1
	const [principal, setPrincipal] = useState(null)
	const [changeToLocalCurrency, setChangeToLocalCurrency] = useState(false)
	const [currency, setCurrency] = useState('USD')
	const [activeCurrency, setActiveCurrency] = useState(null)
	const initialState = {
		origin: null,
		destination: null,
		fromBookingDate: moment(
			new Date().setDate(new Date().getDate() - 14)
		).toDate(),
		toBookingDate: new Date(),
		shipmentIdentifierDetails: {
			shipmentPrefix: '607',
			masterDocumentNumber: '',
		},
		bookingStatus: null,
		product: null,
		commodity: null,
		carrierCode: 'EY',
		flightNumber: '',
		flightDate: null,
	}
	const [bookingSearchFormData, setBookingSearchFormData] =
		useState(initialState)

	const userDetails = getCurrentUser()

	useEffect(() => {
		if (recentBookings) {
			pushPageData(
				pageNames.DASHOARD,
				PAGE_PRIMARY_CATEGORY.USER_PROFILE,
				PAGE_SUB_CATEGORY1.DASHBOARD,
				null,
				null,
				chartDataType
			)
		}

	}, [recentBookings])

	useEffect(() => {
		let bookingListRequest = {
			bookingSearchFormData,
			initialPageNumber,
			defaultOrigin,
		}
		;(async () => {
			try {

				const recentdata = await getBookingListResponseDashboard(
					bookingListRequest
				)
				setRecentBookings(recentdata)
			} catch (error) {
				console.log(error.message)
				addError('BOOKING_LIST_ERROR', error)
				pushPageData(
					pageNames.DASHOARD,
					PAGE_PRIMARY_CATEGORY.USER_PROFILE,
					PAGE_SUB_CATEGORY1.DASHBOARD
				)
			}
			
		})()
		const principal = getPrincipal()
		setPrincipal(principal)
		
	}, [])

	useEffect(() => {
		let requestBody = {
			requestID: uuidv4(),
			agentCode:
				userDetails && userDetails.agentCode ? userDetails.agentCode : null,
			dataTimeFrame: period,
			localDate: getDate(),
		}
		;(async () => {
			try {
				setIsLoading(true)
				const chartData = await getDashboardChartData(requestBody)
				chartData &&
					chartData.lastUpdatedDate &&
					setLastUpdatedDate(chartData.lastUpdatedDate)
				setChartData(chartData)
				if (
					chartData &&
					chartData.currencyCode &&
					chartData.currencyCode !== 'USD' && activeCurrency !== 'USD'
				) {
					setChangeToLocalCurrency(true)
				}
				setIsLoading(false)
			} catch (error) {
				console.log(error.message)
				setIsLoading(false)
				addError('REVENUE_DATA_ERROR', error)
				}
		})()
	}, [period])

	useEffect(() => {
		if (changeToLocalCurrency) {
			setCurrency(chartData.currencyCode)
		} else {
			setCurrency('USD')
		}
	}, [changeToLocalCurrency])

	const setLocalCur = (val) => {
		setActiveCurrency(val);
	}

	const getDate = () => {
		let localDate = new Date()
		localDate.setDate(localDate.getDate())
		return moment(localDate).format('DD-MMM-YYYY')
	}
	//console.log(chartDataType,"chart")
	return (
		<div className='dashboard-container container-lg'>
			{principal?.roles?.length && (
			principal.roles.includes(
				USER_PRIVLILEGES.REVENUE_INFOGRAPHICS_PRIVILEGE) || principal.roles.includes(
					USER_PRIVLILEGES.GSA_ADMIN_USER_PRIVILEGE)
			) ? (
				<>
					<ChartFilter
						period={period}
						setPeriod={setPeriod}
						chartDataType={chartDataType}
						setChartDataType={setChartDataType}
						pushEventBookingDashboard={pushEventBookingDashboard}
						pushEventBookingTime={pushEventBookingTime}
						lastUpdatedDate={lastUpdatedDate}
						chartData={chartData}
						currency={currency}
						setLocalCur={setLocalCur}
						changeToLocalCurrency={changeToLocalCurrency}
						setChangeToLocalCurrency={setChangeToLocalCurrency}
					/>
					<div className='row'>
						<div className='col-12 col-md-12 col-lg-4 col-xl-4 custom-mobile-mb-4'>
							<div className='dashboard-component'>
								<SummaryChart
									period={period}
									chartData={chartData}
									chartDataType={chartDataType}
									isLoading={isLoading}
									changeToLocalCurrency={changeToLocalCurrency}
									currency={currency}
								/>
							</div>
						</div>
						<div className='col-12 col-md-12 col-lg-4 col-xl-4'>
							<div className='row custom-mobile-row' style={{ height: '100%' }}>
								<div className='col-12 mb-4 pl-0 pr-0'>
									<div className='dashboard-component'>
										<ProductChart
											chartData={chartData}
											chartDataType={chartDataType}
											isLoading={isLoading}
											changeToLocalCurrency={changeToLocalCurrency}
										/>
									</div>
								</div>
								<div className='col-12 pl-0 pr-0 custom-mobile-mb-4'>
									<div className='dashboard-component'>
										<ServiceTypeChart
											chartData={chartData}
											chartDataType={chartDataType}
											isLoading={isLoading}
											changeToLocalCurrency={changeToLocalCurrency}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='col-12 col-md-12 col-lg-4 col-xl-4'>
							<div className='dashboard-component'>
								<RevenueIndicator
									chartData={chartData}
									chartDataType={chartDataType}
									isLoading={isLoading}
									changeToLocalCurrency={changeToLocalCurrency}
									currency={currency}
								/>
							</div>
						</div>
					</div>
				</>
			) : (
				<></>
			)}
			<div className='row mt-4'>
				<div className='col-12 col-lg-9  custom-mobile-mb-4'>
					<RecentBookingDetails
						recentBookings={recentBookings}
						isLoading={isLoading}
					/>
				</div>
				<div className='col-12 col-lg-3'>
					<div className='row custom-mobile-row' style={{ height: '100%' }}>
						<div className='col-12 mb-4 custom-pr-0 pl-0'>
							<div className='dashboard-component'>
								<NewBookingTile
								//pushEventDashboardBookingandTracking={pushEventDashboardBookingandTracking} 
								/>
							</div>
						</div>
						<div className='col-12 custom-pr-0 pl-0'>
							<div className='dashboard-component'>
								<ShipmentTracking
								pushEventDashboardBookingandTracking={pushEventDashboardBookingandTracking}/>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			{principal?.roles?.length &&
				(principal.roles.includes(USER_PRIVLILEGES.ALLOTMENT_PRIVILEGE) || principal.roles.includes(
					USER_PRIVLILEGES.GSA_ADMIN_USER_PRIVILEGE)) ? (
				<AllotmentDetails />
			) : (
				<></>
			)}
			{isLoading ? <LoadingIndicator /> : <></>}
			<NewsFeed />
		</div>
	)
}
export default DashboardContainer
